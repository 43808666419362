import { graphql } from "gatsby";
import * as React from "react";
import RenderIndexPage from "../components/renderIndexPage";
import { boxModeGen } from "../components/utils";

export const postsQuery = graphql`
  query indexData {
    posts: allStrapiOkCarbonPosts(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          slug
          strapiId
          title
          updated_at
          published_at
          otherPicksTitle
          mainPickTitle
          interviewIntro
          interviewContent
          intervieweeName
          created_at
          products {
            id
            created_at
            discountedPrice
            slug
            published_at
            price
            name
            link
            linkText
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    width: 310
                    aspectRatio: 1
                    transformOptions: { cropFocus: ATTENTION, trim: 4 }
                  )
                }
                extension
                publicURL
              }
              caption
              alternativeText
            }
            updated_at
          }
          main_product_pick {
            created_at
            description
            linkText
            link
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    aspectRatio: 1.3
                    width: 650
                    transformOptions: { cropFocus: CENTER, trim: 4 }
                  )
                }
                extension
                publicURL
              }
              caption
              alternativeText
            }
            name
            price
            published_at
            slug
            updated_at
          }
        }
      }
    }
  }
`;

const IndexPage = ({ pageContext, data }) => {
  return <RenderIndexPage posts={data.posts.edges} boxMode={boxModeGen()} />;
};

export default IndexPage;
